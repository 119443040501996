'use strict'

angular
    .module('cb.order-progress', [])
    .controller('OrderProgressController', ["$scope", "$filter", "$timeout", "StaffService", "NotificationService", "SaleOrderService", function ($scope, $filter, $timeout, StaffService, NotificationService, SaleOrderService) {

        $scope.init = function () {
            $scope.staffList = [];
            $scope.orders = [];
            $scope.Pagination = {};
            $scope.OrdersPageNumber = 0;
            $scope.NameFilter;
            $scope.timeout = $timeout(function () { });
            getSalesPeople();
        };

        $scope.$watch('OrdersPageNumber', function () {
            if ($scope.OrdersPageNumber == 0 && $scope.selectedStaff)
                $scope.OrdersPageNumber = 1;
            else
                getOrdersForStaff();
        });

        function getSalesPeople() {
            $scope.gettingSalesPeoplePromise = StaffService.getAllStaff(); 
            $scope.gettingSalesPeoplePromise.then(output => {
                $scope.staffList = output.data.StaffList;
            }, err => {
                NotificationService.alert('Staff Error', 'There was an error trying to retrieve the list of staff members.', 'error', {});
            });
        };

        $scope.activeStaff = function (staff) {
            if ($scope.selectedStaff)
                return staff.Id == $scope.selectedStaff.Id ? 'active' : '';
        };

        $scope.selectStaff = function (staff) {
            if (!$scope.selectedStaff || staff.Id != $scope.selectedStaff.Id) {
                if (!$scope.selectedStaff) {
                    $scope.selectedStaff = staff;
                    $scope.OrdersPageNumber = 1;
                } else {
                    $scope.selectedStaff = staff;
                    $scope.OrdersPageNumber = 0;
                };
            };
        };

        function getOrdersForStaff() {
            if (!$scope.selectedStaff)
                return;

            var data = {
                Pagination: {
                    StartPage: $scope.OrdersPageNumber - 1,
                    PageSize: 15
                },
                SalesPersonId: $scope.selectedStaff.Id
            };

            if ($scope.NameFilter)
                data.Pagination.Filter = $scope.NameFilter;

            $scope.gettingSaleOrdersPromise = SaleOrderService.getSaleOrdersForStaff(data);
            $scope.gettingSaleOrdersPromise.then(output => {
                $scope.orders = output.data.Orders;
                $scope.Pagination = output.data.Pagination;
            }, err => {
                NotificationService.alert('Orders Error', 'There was an error trying to retrieve the list of orders for this staff member.', 'error', {});
            });
        };

        $scope.filterItems = function () {
            $timeout.cancel($scope.timeout);
            $scope.timeout = $timeout(() => {
                $scope.OrdersPageNumber = 0;
            }, 500);
        };
    }])
